<template>
  <TypeOfHeatPumpFormField :choices="choices" v-on="$listeners" />
</template>

<script>
import { Selectable } from 'chimera/all/components/models/Selectable'
import TypeOfHeatPumpFormField from 'chimera/all/components/form/fields/typeOfHeatPump/TypeOfHeatPumpFormField.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'TypeOfHeatPumpFormPart',

  components: {
    TypeOfHeatPumpFormField
  },

  /**
   * @returns {{defaultChoices: Selectable[], alternativeHybridChoices: Selectable[]}}
   */
  data () {
    return {
      defaultChoices: this.initializeChoices(),
      alternativeHybridChoices: this.initializeAlternativeChoices()
    }
  },

  computed: {
    ...mapGetters({
      getData: 'lead/getData'
    }),

    /**
     * @returns {Selectable[]}
     */
    choices () {
      const typeOfExistingRadiatorStore = this.$store.getters['lead/getData'](
        'type-of-existing-radiator'
      )
      const intendsToReplaceRadiatorStore = this.$store.getters['lead/getData'](
        'intends-to-replace-radiator'
      )

      const typeOfExistingRadiator = Array.isArray(typeOfExistingRadiatorStore)
        ? typeOfExistingRadiatorStore[0].value
        : null
      const intendsToReplaceRadiator = Array.isArray(
        intendsToReplaceRadiatorStore
      )
        ? intendsToReplaceRadiatorStore[0].value
        : null

      const hybridChoicesOnly =
        typeOfExistingRadiator ===
          this.$t('field.type-of-existing-radiator.options.regular-radiator') &&
        intendsToReplaceRadiator === this.$t('global.no')

      return hybridChoicesOnly
        ? this.alternativeHybridChoices
        : this.defaultChoices
    }
  },

  /**
   * @returns {Promise<void>}
   */
  async beforeMount () {
    if (this.choices === this.alternativeHybridChoices) {
      await this.$store.dispatch('lead/add', {
        key: 'type-of-heat-pump',
        value: this.$i18n.t('field.type-of-heat-pump.options.hybrid-heat-pump')
      })
    }
  },

  methods: {
    /**
     * @returns {Selectable[]}
     */
    initializeChoices () {
      // Initialization code for defaultChoices
      return [
        new Selectable(
          this.$i18n.t('field.type-of-heat-pump.options.air-air-heat-pump'),
          this.$i18n.t('field.type-of-heat-pump.options.air-air-heat-pump'),
          this.$i18n.t('field.type-of-heat-pump.options.air-air-heat-pump'),
          {
            groupHelp: `<strong>${this.$i18n.t(
              'field.type-of-heat-pump.options.air-air-heat-pump'
            )}</strong> haalt warmte uit de buitenlucht en circuleert deze binnen voor verwarming. Veel gebruikt in ductless mini-split systemen.`
          }
        ),
        new Selectable(
          this.$i18n.t('field.type-of-heat-pump.options.air-water-heat-pump'),
          this.$i18n.t('field.type-of-heat-pump.options.air-water-heat-pump'),
          this.$i18n.t('field.type-of-heat-pump.options.air-water-heat-pump'),
          {
            groupHelp: `<strong>${this.$i18n.t(
              'field.type-of-heat-pump.options.air-water-heat-pump'
            )}</strong> verplaatst luchtwarmte naar waterverwarming voor ruimteverwarming of warm water en is geschikt voor radiatoren en vloerverwarming.`
          }
        ),
        new Selectable(
          this.$i18n.t(
            'field.type-of-heat-pump.options.ground-water-heat-pump'
          ),
          this.$i18n.t(
            'field.type-of-heat-pump.options.ground-water-heat-pump'
          ),
          this.$i18n.t(
            'field.type-of-heat-pump.options.ground-water-heat-pump'
          ),
          {
            groupHelp: `<strong>${this.$i18n.t(
              'field.type-of-heat-pump.options.ground-water-heat-pump'
            )}</strong> onttrekt warmte uit de grond en verplaatst deze naar water voor verwarmingsdoeleinden. Efficiënt en duurzaam, met gebruik van stabiele grondtemperaturen.`
          }
        ),
        new Selectable(
          this.$i18n.t('field.type-of-heat-pump.options.water-water-heat-pump'),
          this.$i18n.t('field.type-of-heat-pump.options.water-water-heat-pump'),
          this.$i18n.t('field.type-of-heat-pump.options.water-water-heat-pump'),
          {
            groupHelp: `<strong>${this.$i18n.t(
              'field.type-of-heat-pump.options.water-water-heat-pump'
            )}</strong> gebruikt grondwater als warmtebron voor verwarmingssystemen. Het circuleert water via warmtewisselaars om de warmte-energie over te dragen.`
          }
        ),
        new Selectable(
          this.$i18n.t('field.type-of-heat-pump.options.hybrid-heat-pump'),
          this.$i18n.t('field.type-of-heat-pump.options.hybrid-heat-pump'),
          this.$i18n.t('field.type-of-heat-pump.options.hybrid-heat-pump'),
          {
            groupHelp: `<strong>${this.$i18n.t(
              'field.type-of-heat-pump.options.hybrid-heat-pump'
            )}</strong> combineert traditionele verwarming (bv. gas of olie) met een warmtepomp voor efficiënte verwarming en koeling, met back-upverwarming indien nodig.`
          }
        ),
        new Selectable(
          this.$i18n.t('field.type-of-heat-pump.options.unknown'),
          this.$i18n.t('field.type-of-heat-pump.options.unknown'),
          this.$i18n.t('field.type-of-heat-pump.options.unknown')
        )
      ]
    },

    /**
     * @returns {Selectable[]}
     */
    initializeAlternativeChoices () {
      // Initialization code for alternativeHybridChoices
      return [
        new Selectable(
          this.$i18n.t('field.type-of-heat-pump.options.hybrid-heat-pump'),
          this.$i18n.t('field.type-of-heat-pump.options.hybrid-heat-pump'),
          this.$i18n.t('field.type-of-heat-pump.options.hybrid-heat-pump'),
          {
            groupHelp:
              'Wanneer je standaard radiatoren wil behouden, dan is een hybride warmtepomp de enige & beste optie voor jouw situatie. Een hybride warmtepomp werkt in combinatie met een CV-ketel op aardgas.'
          }
        )
      ]
    }
  }
}
</script>
