<template>
  <FormModal title="Type van de bestaande radiatoren" show-back-btn>
    <template #body>
      <TypeOfExistingRadiatorFormStep :progress-value="progressValue" />
    </template>
  </FormModal>
</template>

<script>
import FormModal from 'chimera/all/themes/blueflow/components/layout/FormModal'
import AbstractFormPage from 'chimera/all/components/page/AbstractFormPage'
import TypeOfExistingRadiatorFormStep from '~/components/form/steps/typeOfExistingRadiator/TypeOfExistingRadiatorFormStep'

export default {
  components: {
    TypeOfExistingRadiatorFormStep,
    FormModal
  },

  extends: AbstractFormPage,

  layout: 'form',

  /**
   * @returns {object}
   */
  data () {
    return {
      headTitle: 'Type radiator',
      headDescription: 'Geef aan naar wat voor type warmtepomp installatie je op zoek bent.',
      path: '/offertes-aanvragen/type-radiator',
      progressValue: 60,
      checkoutStep: 4
    }
  }
}
</script>
